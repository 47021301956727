import {
  Component,
  ChangeDetectionStrategy,
  OnDestroy
} from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { takeUntil, Subject, filter } from 'rxjs';

import { SplashScreenService } from './_metronic/partials/layout/splash-screen/splash-screen.service';
import { AppMonitoringService } from './core/services/app-monitoring.service';
import { AuthService } from './modules/auth';
import { NavigationService } from './modules/shared/services/navigation.service';


@Component({
  // tslint:disable-next-line:component-selector
  selector: 'body[root]',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnDestroy {

  private unsubscribe$ = new Subject<void>();
  constructor(
    private readonly route: ActivatedRoute,
    private readonly auth: AuthService,
    private splashScreenService: SplashScreenService,
    private router: Router,
    private appMonitoringService: AppMonitoringService,
    private nG: NavigationService,
  ) {
    this.router.events.pipe(takeUntil(this.unsubscribe$), filter(event => event instanceof NavigationEnd))
    .subscribe((event) => {
        // hide splash screen
        this.splashScreenService.hide();

        // scroll to top on every route change
        window.scrollTo(0, 0);

        // to display back the body content
        setTimeout(() => {
          document.body.classList.add('page-loaded');
        }, 500);
    });
  }

  handleError(error: Error) {
    this.appMonitoringService.logException(error); // Manually log exception
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
  }
}
